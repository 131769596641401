import { createRouter, createWebHistory, type RouteRecordRaw } from "vue-router"
import { useGlobalStore } from "../stores/useGlobalStore"
import { ls } from "@/utils/localStorage"

const routes: RouteRecordRaw[] = [
	{
		path: "/",
		component: () => import("@/views/Index.vue"),
	},
	// Unlock screen
	{
		path: "/unlock",
		name: "unlock",
		component: () => import("@/views/UnlockPage.vue"),
	},
	// Onboarding
	{
		path: "/onboarding",
		name: "onboarding",
		component: () => import("@/views/onboarding/Onboarding.vue"),
	},
	{
		path: "/password",
		name: "password",
		component: () => import("@/views/onboarding/CreatePassword.vue"),
	},
	{
		path: "/import-wallet",
		name: "import",
		component: () => import("@/views/onboarding/ImportSeed.vue"),
	},
	{
		path: "/new-wallet",
		name: "new-wallet",
		component: () => import("@/views/onboarding/NewWallet.vue"),
	},

	// Wallet
	{
		path: "/wallet",
		name: "wallet",
		component: () => import("@/views/WalletPage.vue"),
	},
	{
		path: "/wallets",
		name: "wallets",
		component: () => import("@/views/WalletsPage.vue"),
	},

	// Send
	{
		path: "/send",
		name: "send",
		component: () => import("@/views/send/SendPage.vue"),
	},
	{
		path: "/send/token",
		name: "send-token",
		component: () => import("@/views/send/SendInputPage.vue"),
	},
	{
		path: "/send/confirm",
		name: "send-confirm",
		component: () => import("@/views/send/SendConfirmPage.vue"),
	},
	{
		path: "/send/fee",
		name: "send-fee",
		component: () => import("@/views/send/SendEditFeePage.vue"),
	},

	// Receive
	{
		path: "/receive",
		name: "receive",
		component: () => import("@/views/receive/ReceivePage.vue"),
	},
	{
		path: "/receive/qr",
		name: "qr",
		component: () => import("@/views/receive/QrPage.vue"),
	},

	// Token
	{
		path: "/token/:token",
		name: "token",
		component: () => import("@/views/token/TokenPage.vue"),
	},
	// {
	// 	path: "/token/:token/details",
	// 	name: "token-details",
	// 	component: () => import("@/views/token/TokenDetailsPage.vue"),
	// },
	{
		path: "/token/details/:id",
		name: "token-details",
		component: () => import("@/views/token/TokenDetailsPage.vue"),
	},

	// Swap
	{
		path: "/swap",
		name: "swap",
		component: () => import("@/views/swap/SwapPage.vue"),
	},
	{
		path: "/swap/confirm",
		name: "swap-confirm",
		component: () => import("@/views/swap/SwapConfirmPage.vue"),
	},

	// Settings
	{
		path: "/settings",
		name: "settings",
		component: () => import("@/views/settings/SettingsPage.vue"),
	},
	{
		path: "/general",
		name: "general",
		component: () => import("@/views/settings/GeneralPage.vue"),
	},
	{
		path: "/security",
		name: "security",
		component: () => import("@/views/settings/SecurityPage.vue"),
	},
	{
		path: "/notification",
		name: "notification",
		component: () => import("@/views/settings/NotificationPage.vue"),
	},
	{
		path: "/about",
		name: "about",
		component: () => import("@/views/settings/AboutPage.vue"),
	},
	{
		path: "/terms",
		name: "terms",
		component: () => import("@/views/settings/TermsPage.vue"),
	},

	// Notifications
	{
		path: "/notifications",
		name: "notifications",
		component: () => import("@/views/NotificationsPage.vue"),
	},

	// Add new token
	{
		path: "/add-token",
		name: "addToken",
		component: () => import("@/views/addToken/AddTokenPage.vue"),
	},
	{
		path: "/add-token/custom",
		name: "addTokenCustom",
		component: () => import("@/views/addToken/CustomTokenPage.vue"),
	},
	{
		path: "/add-token/custom/network",
		name: "addTokenNetwork",
		component: () => import("@/views/addToken/NetworkPage.vue"),
	},
]

const router = createRouter({
	history: createWebHistory(),
	routes,
})

router.beforeEach(async (to, from, next) => {
	const isAuth = ls.get("auth") || null
	const password = ls.get("password") || null
	const unlocked = ls.get("unlocked") || null

	// if (isAuth && password?.length && !unlocked && to.name !== "unlock") {
	// 	return next({ name: "unlock" })
	// }

	if (from.name === "new-wallet") {
		ls.remove("new-wallet")
	}
	if (from.name === "wallets" && to.name === "security") {
		next({ name: "wallet" })
	}
	if (from.name === "wallet" && to.name === "import") {
		return false
	}
	next()
})

router.afterEach((to, from, next) => {
	window.scrollTo(0, 0)
	if (to.name === "wallet" || to.name === "onboarding" || to.name === "unlock" || to.name === "new-wallet") {
		window.Telegram.WebApp.BackButton.hide()
	} else {
		window.Telegram.WebApp.BackButton.show()
	}
})
export default router
