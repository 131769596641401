<script setup lang="ts">
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useDark } from '@vueuse/core';
// import WebApp from '@twa-dev/sdk';
import Utils from './utils';
import { getCurrentWallet, getWallets } from './jsBackend/wallet';
import { initTron } from './jsBackend/tronweb';
import { ls } from './utils/localStorage';
import { usePrimeVue } from 'primevue/config';
import { initNetworkDb } from './jsBackend/network';
const PrimeVue = usePrimeVue();

const router = useRouter();
window.Telegram.WebApp.BackButton.onClick(() => {
  router.go(-1)
})
onMounted(async () => {
  const isDark = useDark()
  if (isDark.value) PrimeVue.changeTheme('aura-light-blue', 'aura-dark-blue', 'theme-link', () => { })
  ls.remove('secure-started')
  // @ts-ignore
  const { activeNetwork } = await initNetworkDb()
  const currentWallet = await getCurrentWallet()
  if (currentWallet && activeNetwork?.api) {
    initTron(currentWallet.private_key, activeNetwork.api)
  }
  // Preload Audio files that we are going to use
  // Utils.PreLoadAudio('Success.mp3');
  // Utils.PreLoadAudio('Failed.mp3');
  const wallets = await getWallets();
  // const lockTime = ls.get('lock-time') || null
  // if (!lockTime || lockTime === 'undefined') ls.set('lock-time', (5 * 60 * 1000).toString())
  const auth = ls.get('auth') || null
  const password = ls.get('password') || null
  // ls.remove('unlocked')
  if (!wallets?.length || !auth) router.push('/onboarding');
  if (password) router.push({ name: 'unlock'})
  // window.addEventListener('blur', () => {
    // ls.set('last-auth', Date.now().toString())
  // })
  // window.addEventListener('focus', () => {
    // const isAuth = ls.get('auth') || null
    // const password = ls.get('password') || null
    // if (isAuth && password) {
    //   const currentLockTime = ls.get('lock-time') || 5 * 60 * 1000
    //   const lastAuth = ls.get('last-auth') || 0
    //   const now = Date.now()
    //   if ((now - +lastAuth) > +currentLockTime) {
    //     ls.remove('unlocked')
    //     router.push({ name: 'unlock' })
    //   }
    // }
  // })

  //если юзер пытается зайти через браузер в мини-апп то перекидываем на главную страницу лендинга
  if(Telegram.WebView.initParams.hasOwnProperty('tgWebAppData'))
  {
      console.log('qqq')
  }
  else
  {
    window.location.replace('https://netts.io/');
  }
});

// WebApp.expand();
</script>

<template>
  <router-view class="main-wrapper ">
  </router-view>
</template>

<style lang="scss">
.main-wrapper {
  min-height: 100vh;
}

@supports (height: 100dvh) {
  .main-wrapper {
    min-height: 100dvh;
  }
}
</style>