export function getRandomInt(max: number) {
	return Math.floor(Math.random() * max)
}

export function generateUniqueRandomNumbers(n: number) {
	const array = Array.from({ length: n }, (_, i) => i)

	// Выбрать из этого массива 3 случайных неповторяющихся числа
	const randomIndices: number[] = []
	for (let i = 0; i < 3; i++) {
		let index
		do {
			index = Math.floor(Math.random() * array.length)
		} while (randomIndices.includes(index))
		randomIndices.push(index)
	}

	// Отсортировать полученный массив по возрастанию
	const result = randomIndices.map((index) => array[index]).sort((a, b) => a - b)

	return result
}

export function shuffleArray(array: any[]) {
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1))
		;[array[i], array[j]] = [array[j], array[i]]
	}
	return array
}

export function sortArray(arr: number[]): number[] {
	return arr.sort((a, b) => a - b)
}

export function concatString(str: string) {
	if (!str) return
	if (str.length <= 10) return str
	return str.slice(0, 5) + "...." + str.slice(-5)
}

export function formatNum(num: string | number, decimal = 3) {
	return Number(Number(num).toFixed(decimal))
}
