import { useTronStore } from "@/stores/tronStore"
import { ls } from "@/utils/localStorage"
import TronWeb from "tronweb"
import * as secp256k1 from "tiny-secp256k1"
import { BIP32Factory } from "bip32"
import * as bip39 from "bip39"
import type { CurrencyType } from "@/config/tokens"
import { getActiveNetwork } from "./network"
// TODO: Удалить
import { createClient } from "@supabase/supabase-js"
import type { ISendData } from "@/@types"
import type { IWallet } from "@/db"

export function initTron(privateKey: string, api: string) {
	const tronStore = useTronStore()
	const tronWeb = new TronWeb({
		fullHost: api,
		privateKey: privateKey,
	})
	tronStore.setTronInstance(tronWeb)
	return tronWeb
}

export async function getTrxBalance() {
	const tronStore = useTronStore()
	const tronWeb = tronStore.getTronInstance
	if (!tronWeb) return { sun: 0, short: "0" }
	const sunBalance = await tronWeb.trx.getBalance()
	const shortBalance = tronWeb.fromSun(sunBalance)
	return { sun: sunBalance, short: shortBalance }
}

export async function getAccountAvailableBandwidth() {
	try {
		const tronStore = useTronStore()
		const tronWeb = tronStore.getTronInstance

		if (!tronWeb) {
			throw new Error("TronWeb instance is not initialized")
		}

		const address = tronWeb.defaultAddress.base58 as string

		// Get account resources
		const resources = await tronWeb.trx.getAccountResources(address)

		// Log the resources information
		console.log("Account Resources:", resources)

		let NetLimit = 0
		if (resources.NetLimit !== undefined && resources.NetLimit !== null) {
			NetLimit = resources.NetLimit
		}

		let NetUsed = 0
		if (resources.NetUsed !== undefined && resources.NetUsed !== null) {
			NetUsed = resources.NetUsed
		}

		let freeNetUsed = 0
		if (resources.freeNetUsed !== undefined && resources.freeNetUsed !== null) {
			freeNetUsed = resources.freeNetUsed
		}

		let freeNetLimit = 0
		if (resources.freeNetLimit !== undefined && resources.freeNetLimit !== null) {
			freeNetLimit = resources.freeNetLimit
		}

		// Get the available bandwidth
		// @ts-ignore
		const availableBandwidth = freeNetLimit + NetLimit - (freeNetUsed + NetUsed)

		// Log available bandwidth
		console.log("Available Bandwidth:", availableBandwidth)

		return availableBandwidth
	} catch (error) {
		console.error("Error getting account resources:", error)
	}
}

export async function getEnergyRentalPrices() {
	var endpoint_url = import.meta.env.VITE_APP_URL

	// const url = "https://dev.mageo.ru/api/get_energy_rental_prices"
	const url = `${endpoint_url}api/get_energy_rental_prices`

	try {
		const response = await fetch(url)
		if (!response.ok) {
			throw new Error(`HTTP error! Status: ${response.status}`)
		}
		const data = await response.json()

		console.log(data)

		return data.result
	} catch (error) {
		console.error(`❌ Get TRX price Error: ${error}`)
	}
}

export async function getAccountAvailableEnergy() {
	try {
		const tronStore = useTronStore()
		const tronWeb = tronStore.getTronInstance

		if (!tronWeb) {
			throw new Error("TronWeb instance is not initialized")
		}

		const address = tronWeb.defaultAddress.base58 as string

		// Get account resources
		const resources = await tronWeb.trx.getAccountResources(address)

		// Log the resources information
		console.log("Account Resources:", resources)

		let EnergyLimit = 0
		if (resources.EnergyLimit !== undefined && resources.EnergyLimit !== null) {
			EnergyLimit = resources.EnergyLimit
		}

		let EnergyUsed = 0
		if (resources.EnergyUsed !== undefined && resources.EnergyUsed !== null) {
			EnergyUsed = resources.EnergyUsed
		}

		// Get the available bandwidth
		// @ts-ignore
		const availableEnergy = EnergyLimit - EnergyUsed

		// Log available bandwidth
		console.log("Available Energy:", availableEnergy)

		return availableEnergy
	} catch (error) {
		console.error("Error getting account resources:", error)
	}
}

export async function getMaxAmountOfTRXsendByTRXburn(needToActivateRecipient: boolean) {
	const trxBalance = await getTrxBalance()

	let trxNeededForBandwidth = await getTrxNeededForBandwidth(270)
	let trxNeeded = trxNeededForBandwidth

	if (needToActivateRecipient) {
		trxNeeded = trxNeeded + 1
	}

	let result = parseFloat(trxBalance.short) - trxNeeded
	if (result < 0) {
		return 0
	}

	return result
}

export async function userCanBurnTRXforTransfer(amount: number, needToActivateRecipient: boolean) {
	const trxBalance = await getTrxBalance()

	let trxNeededForBandwidth = await getTrxNeededForBandwidth(270)
	let trxNeeded = amount + trxNeededForBandwidth

	if (needToActivateRecipient) {
		trxNeeded = trxNeeded + 1
	}

	if (parseFloat(trxBalance.short) >= trxNeeded) {
		return true
	}

	return false
}

export async function getBandwidthByTRXburning(trxToBurn: number) {
	try {
		const bandwidthPrice = 1000 //await tronWeb.trx.getBandwidthPrice();

		const bandwidth = trxToBurn * bandwidthPrice

		return bandwidth
	} catch (error) {
		console.error("Error calculating TRX needed for bandwidth:", error)
		throw error
	}
}

export async function getTrxNeededForBandwidth(bandwidthNeeded: number) {
	try {
		const tronStore = useTronStore()
		const tronWeb = tronStore.getTronInstance

		if (!tronWeb) {
			throw new Error("TronWeb instance is not initialized")
		}

		// Get the current price of burning TRX for bandwidth
		const bandwidthPrice = 1000 //await tronWeb.trx.getBandwidthPrice();

		// Calculate the TRX needed for a basic transfer (assuming 270 bandwidth)
		// const bandwidthNeeded = 270;
		const trxNeeded = bandwidthNeeded * bandwidthPrice

		// Convert from SUN to TRX
		const trxNeededInTrx = parseFloat(tronWeb.fromSun(trxNeeded))

		console.log("TRX needed to burn for transfer:", trxNeededInTrx)

		return trxNeededInTrx
	} catch (error) {
		console.error("Error calculating TRX needed for bandwidth:", error)
		throw error
	}
}

export async function getTrxNeededForEnergy(energyAmountNeeded: number) {
	try {
		const tronStore = useTronStore()
		const tronWeb = tronStore.getTronInstance

		if (!tronWeb) {
			throw new Error("TronWeb instance is not initialized")
		}

		// Get the current price of burning TRX for bandwidth
		const energyPrice = 210 //await tronWeb.trx.getBandwidthPrice();

		// Calculate the TRX needed for a basic transfer (assuming 270 bandwidth)
		// const bandwidthNeeded = 270;
		const sunNeeded = energyAmountNeeded * energyPrice

		// Convert from SUN to TRX
		const trxNeededInTrx = parseFloat(tronWeb.fromSun(sunNeeded))

		console.log("TRX needed to burn for transfer:", trxNeededInTrx)

		return trxNeededInTrx
	} catch (error) {
		console.error("Error calculating TRX needed for bandwidth:", error)
		throw error
	}
}

export async function isAccountActivated(address: string) {
	try {
		const tronStore = useTronStore()
		const tronWeb = tronStore.getTronInstance

		if (!tronWeb) {
			throw new Error("TronWeb instance is not initialized")
		}

		const account = await tronWeb.trx.getAccount(address)

		// Check if account has a non-empty address field or balance
		const isActivated = account.address && tronWeb.toDecimal(account.balance) > 0
		console.log(`Account ${address} is ${isActivated ? "activated" : "not activated"}.`)

		return isActivated
	} catch (error) {
		console.error(`Error fetching account info or transactions: ${error}`)
		return false
	}
}

export async function getMaxSendTRXamount(recipient: string) {
	const needToActivateRecipient = !(await isAccountActivated(recipient))

	const trxBalance = await getTrxBalance()

	const availableBandwidth = (await getAccountAvailableBandwidth()) as number

	const tmp = 1

	if (availableBandwidth >= 270) {
		let maxAmount = parseFloat(trxBalance.short)
		if (needToActivateRecipient) {
			maxAmount -= 1
		}

		if (maxAmount < 0) {
			return 0
		} else {
			return maxAmount
		}
	} else {
		return await getMaxAmountOfTRXsendByTRXburn(needToActivateRecipient)
	}
}

export async function checkUserCanMakeTransferTRX(amount: number, recipient: string) {
	const needToActivateRecipient = !(await isAccountActivated(recipient))

	const trxBalance = await getTrxBalance()

	const availableBandwidth = (await getAccountAvailableBandwidth()) as number

	const tmp = 1

	let fees = []
	let messages = []
	let userCanMakeTransfer = false

	if (needToActivateRecipient) {
		fees.push({
			description: "1 TRX to activate recipient account",
		})
	}

	const bandwidthForTRXtracnfer = 270

	let trxNeeded = 0

	if (availableBandwidth >= bandwidthForTRXtracnfer) {
		// trxNeeded = amount

		fees.push({
			description: `${bandwidthForTRXtracnfer} bandwidth`,
		})

		if (needToActivateRecipient) {
			trxNeeded += 1
		}

		if (parseFloat(trxBalance.short) >= trxNeeded + amount) {
			userCanMakeTransfer = true
		} else {
			messages.push({
				description: "Insufficient TRX balance",
			})
		}
	} else {
		let trxNeededForBandwidth = await getTrxNeededForBandwidth(bandwidthForTRXtracnfer)

		fees.push({
			description: `${trxNeededForBandwidth} TRX to burn for bandwidth`,
		})

		// trxNeeded += amount + trxNeededForBandwidth
		trxNeeded += trxNeededForBandwidth

		if (needToActivateRecipient) {
			trxNeeded += 1
		}

		if (parseFloat(trxBalance.short) >= trxNeeded + amount) {
			userCanMakeTransfer = true
		} else {
			messages.push({
				description: "Insufficient TRX balance",
			})
		}
	}

	let maxAmount = null

	maxAmount = parseFloat(trxBalance.short) - trxNeeded

	return {
		userCanMakeTransfer: userCanMakeTransfer,
		maxAmount: maxAmount,
		fees: fees,
		messages: messages,
	}
}

// Function to check USDT balance
export async function checkUSDTBalance(address: string) {
	try {
		const tronStore = useTronStore()
		const tronWeb = tronStore.getTronInstance as TronWeb

		// USDT contract address
		const activeNet = await getActiveNetwork()
		if (!activeNet) throw new Error("Нет активного network")
		const usdtContractAddress = activeNet.usdtContractAddress
		// Get contract instance
		const contract = await tronWeb.contract().at(usdtContractAddress)
		// Call the balanceOf function
		const balance: number = await contract.methods.balanceOf(address).call()
		// Convert the balance from Sun to USDT (USDT has 6 decimals)
		const usdtBalance = tronWeb.toDecimal(balance) / 1e6
		// return {
		// 	sun: balance,
		// 	short: usdtBalance,
		// }
		return usdtBalance
	} catch (error) {
		console.error("Error getting USDT balance:", error)
	}
}

export async function checkUserCanMakeTransferUSDT(amount: number, recipient: string) {
	const tronStore = useTronStore()
	const tronWeb = tronStore.getTronInstance

	if (!tronWeb) {
		throw new Error("TronWeb instance is not initialized")
	}

	const address = tronWeb.defaultAddress.base58 as string

	const needToActivateRecipient = !(await isAccountActivated(recipient))

	const trxBalance = await getTrxBalance()

	const availableBandwidth = (await getAccountAvailableBandwidth()) as number

	const availableEnergy = (await getAccountAvailableEnergy()) as number

	const recipientUSDTBalance = await checkUSDTBalance(recipient)

	const walletUSDTBalance = (await checkUSDTBalance(address)) as number

	const tmp = 1

	let fees = []
	let messages = []
	let userCanMakeTransfer = true

	let trx_usdt_price = 0.15

	if (needToActivateRecipient) {
		fees.push({
			trx: 2,
			usdt: 2 * trx_usdt_price,
			text: "1 TRX to activate recipient account",
		})
	}

	const bandwidthForUSDTtracnfer = 345
	const energyNeededWhenRecepientHasNoUsdt = 260570 //28430
	const energyNeededWhenRecepientHasUsdt = 130285 //13430

	let trxNeededToBurn = 0
	let needBurnForEnergy = false
	let needBurnForBandwidth = false

	if (availableBandwidth >= bandwidthForUSDTtracnfer) {
		fees.push({
			trx: 0.345,
			usdt: 0.345 * trx_usdt_price,
			text: `bandwidth`,
		})
	} else {
		let trxNeededForBandwidth = await getTrxNeededForBandwidth(bandwidthForUSDTtracnfer)

		fees.push({
			trx: trxNeededForBandwidth,
			usdt: trxNeededForBandwidth * trx_usdt_price,
			text: `TRX to burn for bandwidth`,
		})

		trxNeededToBurn += trxNeededForBandwidth

		needBurnForBandwidth = true
	}

	let enregyNeeded = null

	if (recipientUSDTBalance == 0) {
		enregyNeeded = energyNeededWhenRecepientHasNoUsdt
	} else {
		enregyNeeded = energyNeededWhenRecepientHasUsdt
	}

	if (availableEnergy >= enregyNeeded) {
		fees.push({
			trx: 0,
			usdt: 0,
			text: `${enregyNeeded} energy`,
		})
	} else {
		let trxNeededForEnergy = await getTrxNeededForEnergy(enregyNeeded)

		fees.push({
			trx: trxNeededForEnergy,
			usdt: trxNeededForEnergy * trx_usdt_price,
			text: `TRX to burn for energy`,
		})

		trxNeededToBurn += trxNeededForEnergy

		needBurnForEnergy = true
	}

	if (needToActivateRecipient) {
		trxNeededToBurn += 1
	}

	let userCanBurn = false

	if (trxNeededToBurn != 0) {
		if (parseFloat(trxBalance.short) >= trxNeededToBurn) {
			userCanBurn = true
		}
	} else {
		userCanBurn = true
	}

	if (userCanBurn == false) {
		userCanMakeTransfer = false

		// messages.push({
		// 	description: 'Insufficient TRX balance, please try feature "use usdt to pay fee"',
		// })
		messages.push('Insufficient TRX balance, please try feature "use usdt to pay fee"')
	}

	let maxAmount = null

	// maxAmount = parseFloat(trxBalance.short) - trxNeededToBurn
	maxAmount = walletUSDTBalance

	return {
		userCanMakeTransfer: userCanMakeTransfer,
		fees: fees,
		maxAmount: maxAmount,
		messages: messages,
		config: {
			need_activation: needToActivateRecipient,
		},
	}
}

export async function checkUserCanMakeTransferUSDT_PAY_FEE_BY_USDT(amount: number, recipient: string) {
	const trxBalance = await getTrxBalance()

	const bandwidthByTRXburning = (await getBandwidthByTRXburning(+trxBalance.short)) as number

	let availableBandwidth = (await getAccountAvailableBandwidth()) as number

	availableBandwidth += bandwidthByTRXburning

	const needToActivateRecipient = !(await isAccountActivated(recipient))

	const energyRentalPrices = (await getEnergyRentalPrices()) as object

	const recipientUSDTBalance = await checkUSDTBalance(recipient)

	const tronStore = useTronStore()
	const tronWeb = tronStore.getTronInstance

	if (!tronWeb) {
		throw new Error("TronWeb instance is not initialized")
	}

	const address = tronWeb.defaultAddress.base58 as string

	const walletUSDTBalance = (await checkUSDTBalance(address)) as number

	const tmp = 1

	let fees = []
	let messages = []
	let userCanMakeTransfer = true

	const bandwidthForUSDTtracnfer = 345

	//TODO refactor to get it from our server
	const TRX_USDT_pirce = 0.15

	let usdt_needed = 0

	let usdtForEnergyRental = 0

	//здесь определяем сколько юзер платит Usdt за аренду энергии
	if (recipientUSDTBalance == 0) {
		console.log("energyRentalPrices", energyRentalPrices)

		usdtForEnergyRental = energyRentalPrices["3X"]["usdt"]
		usdt_needed += usdtForEnergyRental

		fees.push({
			description: `${usdtForEnergyRental} usdt for energy rental`,
		})
	} else {
		usdtForEnergyRental = energyRentalPrices["2X"]["usdt"]
		usdt_needed += usdtForEnergyRental

		fees.push({
			description: `${usdtForEnergyRental} usdt for energy rental`,
		})
	}

	let needBurnForBandwidth = null

	let trxNeededForBandwidth = await getTrxNeededForBandwidth(bandwidthForUSDTtracnfer)

	let usdtForBandwidthRental = trxNeededForBandwidth * TRX_USDT_pirce

	let quotientAvailableBandWidth = Math.floor(availableBandwidth / bandwidthForUSDTtracnfer)

	//если юзер может сделать 1 перевод - субсидируем ему trx на 1 перевод
	if (quotientAvailableBandWidth == 1) {
		usdt_needed += usdtForBandwidthRental
		needBurnForBandwidth = trxNeededForBandwidth

		fees.push({
			description: `${usdtForBandwidthRental} USDT for bandwidth subsidy`,
		})

		// fees.push({
		// 	description: `${needBurnForBandwidth} TRX (${usdtForBandwidthRental} USDT) for bandwidth subsidy`,
		// })
	}

	//если юзер может сделать 0 переводов - субсидируем ему trx на 2 перевода
	if (quotientAvailableBandWidth == 0) {
		usdt_needed += usdtForBandwidthRental * 2
		needBurnForBandwidth = trxNeededForBandwidth * 2

		// fees.push({
		// 	description: `${trxNeededForBandwidth*2} TRX (${usdtForBandwidthRental*2} USDT) for bandwidth subsidy`,
		// })
		fees.push({
			description: `${usdtForBandwidthRental * 2} USDT for bandwidth subsidy`,
		})
	}

	if (needToActivateRecipient) {
		let activation_fee_usdt = TRX_USDT_pirce * 2

		usdt_needed += activation_fee_usdt

		let activation_fee_trx = 2

		// fees.push({
		// 	description:  `${activation_fee_trx} TRX (${activation_fee_usdt} USDT) to activate recipient account`,
		// });

		fees.push({
			description: `${activation_fee_usdt} USDT to activate recipient account`,
		})
	}

	let maxAmount = walletUSDTBalance - usdt_needed

	if (walletUSDTBalance < usdt_needed + amount) {
		userCanMakeTransfer = false

		messages.push({
			description: "Insufficient USDT balance, please deposit more usdt or change amount",
		})
	}

	return {
		userCanMakeTransfer: userCanMakeTransfer,
		maxAmount: maxAmount,
		fees: fees,
		config: {
			trxForBandwidthSubsidy: needBurnForBandwidth,
			need_activation: needToActivateRecipient,
			usdtForEnergyRental: usdtForEnergyRental,
		},
		messages: messages,
	}
}

export async function checkUserCanMakeTransferUSDT_calculateFeesWithBackend(sendForm: ISendData, wallet: IWallet) {
	let transactionResult

	const activeNet = await getActiveNetwork()
	let network = null

	if (activeNet!.api == "https://nile.trongrid.io") {
		network = "nile"
	} else {
		network = "mainnet"
	}

	var endpoint_url = import.meta.env.VITE_APP_URL

	// const res = await fetch("https://dev.mageo.ru/api/usdt_transfer_calculate_fees", {
	const res = await fetch(`${endpoint_url}api/usdt_transfer_calculate_fees`, {
		method: "POST",
		body: JSON.stringify({
			amount: sendForm?.amount,
			recipent: sendForm?.recipent,
			sender_address: wallet?.address,
			sender_privkey: wallet?.private_key,
			network: network,
			trx_for_bandwidth_subsidy: "",
			need_activation: "",
		}),
		headers: {
			"Content-type": "application/json; charset=UTF-8",
		},
	})

	transactionResult = await res.json()

	console.log("calculateFeesWithBackend", transactionResult)

	return transactionResult
}

export async function isValidTronAddress(address: string) {
	try {
		const tronStore = useTronStore()
		const tronWeb = tronStore.getTronInstance

		// Check if the address is valid
		const valid = tronWeb!.isAddress(address)

		console.log("Tron address valid:", valid)
		return valid
	} catch (error) {
		console.error("Error validating Tron address:", error)
		return false
	}
}

export async function isContract(address: string) {
	try {
		const tronStore = useTronStore()
		const tronWeb = tronStore.getTronInstance

		// Get the contract info by address
		const contract = await tronWeb!.trx.getContract(address)

		// If the address has bytecode (i.e., it's a contract), it will be included in the contract object

		let isContractResult = !!(contract.bytecode && contract.bytecode.length > 0)

		console.log("isContract", isContractResult)

		return isContractResult
	} catch (error) {
		// If there's an error, it's likely that the address is not a contract
		return false
	}
}

//TODO: Переделать под свой апи
export async function getUsdtFee() {
	const supabase = createClient(import.meta.env.VITE_SUPABASE_URL, import.meta.env.VITE_SUPABASE_ANON_KEY)
	const { data, error } = await supabase.from("usdtFee").select("original, energy, bandwidth, account, getTrx").order("id", { ascending: false }).limit(1)
	// @ts-ignore
	return data[0]
}

export async function getUsdtBalance() {
	try {
		const tronStore = useTronStore()
		const tronWeb = tronStore.getTronInstance
		if (!tronWeb) {
			return {
				sun: 0,
				short: 0,
			}
		}
		// USDT contract address
		const activeNet = await getActiveNetwork()
		if (!activeNet) throw new Error("Нет активного network")
		const usdtContractAddress = activeNet.usdtContractAddress
		const address = tronWeb.defaultAddress.base58
		// Get contract instance
		const contract = await tronWeb.contract().at(usdtContractAddress)
		// Call the balanceOf function
		const balance: number = await contract.methods.balanceOf(address).call()

		// Convert the balance from Sun to USDT (USDT has 6 decimals)
		const usdtBalance = tronWeb.toDecimal(balance) / 1e6
		return {
			sun: balance,
			short: usdtBalance,
		}
	} catch (error) {
		console.log("❌ Get USDT balance error", error)
		return {
			sun: 0,
			short: 0,
		}
	}
}

export async function getTokenBalance(tokenName: CurrencyType) {
	if (tokenName === "TRX") return await getTrxBalance()
	if (tokenName === "USDT") return await getUsdtBalance()
}

export async function sendTrx(addressTo: string, amount: number) {
	try {
		const tronStore = useTronStore()
		const tronWeb = tronStore.getTronInstance
		if (!tronWeb) return false
		const sunAmount = tronWeb.toSun(amount)
		const transaction = await tronWeb.transactionBuilder.sendTrx(addressTo, +sunAmount)
		const signedTransaction = await tronWeb.trx.sign(transaction)
		const transactionResult = await tronWeb.trx.sendRawTransaction(signedTransaction)
		console.log("✅ TRX send", transactionResult)
		return transactionResult
	} catch (error) {
		console.log("❌ send TRX ", error)
		throw new Error()
	}
}
export async function sendUsdt(addressTo: string, amount: number) {
	try {
		const tronStore = useTronStore()
		const tronWeb = tronStore.getTronInstance
		if (!tronWeb) return false
		const activeNet = await getActiveNetwork()
		if (!activeNet) throw new Error("Нет активного network")
		const usdtContractAddress = activeNet.usdtContractAddress
		// const amount = 1000000; // 1 USDT
		// const amount = body.amount*1000000; // 1 USDT
		const contract = await tronWeb.contract().at(usdtContractAddress)
		const result = await contract.methods.transfer(addressTo, amount * 1000000).send({
			feeLimit: 90000000,
		})

		// console.log("Transaction Result:", result)

		return result
	} catch (error) {
		console.log("❌ send USDT ", error)
		throw new Error()
	}
}

export async function getTrxPrice(): Promise<string> {
	const url = "https://apilist.tronscanapi.com/api/token/price?token=trx"
	try {
		const response = await fetch(url)
		if (!response.ok) {
			throw new Error(`HTTP error! Status: ${response.status}`)
		}
		const data = await response.json()
		ls.set("trx-price", data["price_in_usd"])
		return data["price_in_usd"]
	} catch (error) {
		console.error(`❌ Get TRX price Error: ${error}`)
		return ls.get("trx-price") || "0"
	}
}

export async function getTokenPrice(token: CurrencyType) {
	if (token === "USDT") return 1
	if (token === "TRX") return Number(await getTrxPrice())
}

export interface IBalnce {
	trxPrice: string
	trxBalance: {
		sun: number
		short: string
	}
	usdtBalance: {
		sun: number
		short: number
	}
}
export async function getFullBalance(): Promise<null | IBalnce> {
	try {
		const trxPrice = await getTrxPrice()
		const trxBalance = await getTrxBalance()
		const usdtBalance = await getUsdtBalance()
		// if (!trxPrice || !trxBalance || !usdtBalance) throw new Error()
		return {
			trxPrice,
			trxBalance,
			usdtBalance,
		}
	} catch (error) {
		console.log("❌ ошибка в функции tronweb.ts -> getFullBalance", error)

		return null
	}
}

export async function deriveTronAddressFromMnemonic(mnemonic: string) {
	// const bip32 = BIP32Factory(ecc);
	const bip32 = BIP32Factory(secp256k1)

	// Validate mnemonic
	if (!bip39.validateMnemonic(mnemonic)) {
		throw new Error("Invalid mnemonic phrase")
	}
	// Generate seed from mnemonic
	const seed = await bip39.mnemonicToSeed(mnemonic)
	// Derive BIP32 root key
	const root = bip32.fromSeed(seed)
	// Tron uses a specific derivation path
	const tronPath = "m/44'/195'/0'/0/0"
	const child = root.derivePath(tronPath)
	// Get the private key in hex format
	// @ts-ignore
	const privateKey = child.privateKey.toString("hex")
	// Initialize TronWeb instance
	const activeNet = await getActiveNetwork()
	if (!activeNet) throw new Error("Нет активного network")
	const tronWeb = new TronWeb({
		fullHost: activeNet.api, // Use the official TronGrid API
	})
	// Generate Tron address from the private key
	const address = tronWeb.address.fromPrivateKey(privateKey)
	return {
		address: address,
		privateKey: privateKey,
	}
}
