import { createApp } from "vue"
import { createI18n } from "vue-i18n"
import { createPinia } from "pinia"
import PrimeVue from "primevue/config"
// @ts-ignore
import "primeicons/primeicons.css"
// import "primevue/resources/themes/aura-light-blue/theme.css"
import ToastService from "primevue/toastservice"
import Toast from "primevue/toast"
import Button from "primevue/button"

import { autoAnimatePlugin } from "@formkit/auto-animate/vue"

import App from "./App.vue"
import router from "./router"
import { useGlobalStore } from "./stores/useGlobalStore"
import outclickDirective from "./directives/outclick"

import "./scss/app.scss"
import "./scss/tailwind.scss"

// Import i18n locales
import i18n_en from "./i18n/lang-en"
import i18n_fa from "./i18n/lang-fa"
import i18n_ar from "./i18n/lang-ar"
import i18n_de from "./i18n/lang-de"
import i18n_fr from "./i18n/lang-fr"
import i18n_tr from "./i18n/lang-tr"
import { ls } from "./utils/localStorage"
import type { LocaleType } from "./i18n/localeType"

import * as Sentry from "@sentry/vue";
import truncateDirective from './directives/truncate'

const i18n = createI18n<[LocaleType], "en" | "de">({
	legacy: false,
	locale: ls.get("lang") || "en",
	fallbackLocale: "en",
	messages: {
		en: i18n_en,
		de: i18n_de,
		// fa: i18n_fa,
		// ar: i18n_ar,
		// fr: i18n_fr,
		// tr: i18n_tr,
	},
})

const app = createApp(App)


Sentry.init({
	app,
	dsn: "https://2c7da196c0eb14f412fb1452be9ce622@o474570.ingest.us.sentry.io/4508076453330944",
	integrations: [
	  Sentry.browserTracingIntegration({ router }),
	  Sentry.replayIntegration(),
	],
	// Tracing
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
  


const pinia = createPinia()

app.use(i18n)
app.use(router)
app.use(pinia)
app.use(PrimeVue)
app.use(ToastService)
app.component("Toast", Toast)
app.component("Button", Button)
app.use(autoAnimatePlugin)
app.directive("outclick", outclickDirective)
app.directive("truncate", truncateDirective)

window.pinia = pinia
window.globalStore = useGlobalStore(pinia)

app.mount("#app")
