import type { ObjectDirective } from "vue"

interface HTMLElementWithObserver extends HTMLElement {
	_observer?: ResizeObserver
}

const truncateMiddleText = (element: HTMLElementWithObserver, maxWidth: number) => {
	const text = element.getAttribute("data-fulltext") || element.innerText
	const ellipsis = "...."

	// Сохраняем полный текст в атрибуте 'data-fulltext'
	if (!element.getAttribute("data-fulltext")) {
		element.setAttribute("data-fulltext", text)
	}
	let start = 0
	let end = text.length
  const elScroll = element.scrollWidth
  const elWidth = element.clientWidth
  console.log(element.innerText);
  
	// while (element.scrollWidth > maxWidth && start + 1 < end) {
	// 	start++
	// 	end--
	// 	element.innerText = text.slice(0, start) + ellipsis + text.slice(end)
	// }
}

const truncateDirective: ObjectDirective<HTMLElementWithObserver> = {
	mounted(el: HTMLElementWithObserver) {
		

		const observer = new ResizeObserver(() => {
			truncateMiddleText(el, el.clientWidth)
		})

		observer.observe(el)

		truncateMiddleText(el, el.clientWidth)

		el._observer = observer
	},
	unmounted(el: HTMLElementWithObserver) {
		if (el._observer) {
			el._observer.disconnect()
			delete el._observer
		}
	},
}
export default truncateDirective
